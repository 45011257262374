$darkGrey: #202020;
$darkGreyText: #404040;
$lightGreyText: #bcbcbc;
$facebookBlue: #3b5998;

/* general */
h1, h2, h3, h4, h5, h6, a, p, div {
  font-family: 'Noto Sans', sans-serif;
}

a {
  color: $darkGreyText;
}

body {
  overflow-x: hidden;
}

.hideyho {
  visibility: hidden;
}

.container {
  padding: 0;
}

.no-border {
  border: 0;
}

/* vertical alignment by noahmason */
.va-container {
  display: table;
  height: 100%;
}
.va-center {
  display: table-cell;
  vertical-align: middle;
}

a:hover, a:focus {
  text-decoration: none;
  color: $lightGreyText;
}

a:focus {
  color: $darkGreyText;
}

.bold {
  font-weight: 700;
}

.banner, #services, footer {
  text-align: center;
}

/* header */
header {
  background: #fff;
  height: 87px;
}

.logo img {
  position: relative;
  width: 220px;
  top: 1.2em;
  margin-left: 2em;
}

/* nav */
nav ul {
  list-style: none;
  position: absolute;
  top: 2.85em;
  right: 2.5em;

  li {
    display: inline-block;
  }

  a {
    font-size: 0.9em;
    margin: 0 0.9em;
  }

  > li:after {
    content: "/";
    color: $lightGreyText;
  }

  > li:last-child:after {
    content: "";
  }
}


/* footer */
footer {
  height: 120px;
  background-color: $darkGrey;
  color: #8b8b8b;

  h4 {
    margin-top: 2.5em;
    font-size: 1em;
  }

  h5 {
    font-size: 0.8em;
    margin-top: 3em;
    color: #505050;
  }

  .logo {
    height: 1.4em;
    margin-left: 0.1em;
    position: relative;
    margin-top: -0.8em;
  }
}


/* contact */
#contact {
  padding: 5em 0;
  text-align: center;

  ul {
    list-style: none;
    margin: 3em 0 0 0;
    padding: 0;
    display:inline-block;

    li {
      margin-top: 1em;
      text-align: left;
    }

    .facebook a:hover {
      color: $facebookBlue;
    }

  }

  .icon {
    margin-right: 9px;
  }

  h1 {
    font-size: 3em;
  }

  h2 {
    font-size: 1em;
    margin-top: 3.4em;
  }

}


.contact-form {
  margin: 0 auto;
  width: 350px;
  margin-top: 3.7em;

  input,  textarea {
    display: block;
    width: 350px;
    height: 32px;
    margin-bottom: 10px;
    border-style: none;
    background-color: #ececec;
  }

  textarea {
    height: 160px;
  }

  .secret {
    display: none;
  }

  input[type="submit"] {
    width: 100%;
    margin: 0 auto;
    border: none;
    background-color: #5bc0de;
    color: #fff;

    &:focus {
      outline: 0;
    }
  }

}

.contact-left img {
  position: relative;
  top: 2em;
  left: 2em;
}

*::-webkit-input-placeholder { color: #757575; padding-left: 10px; }
*:-moz-placeholder { color: #757575; padding-left: 10px; }
*:-ms-input-placeholder { color: #757575; padding-left: 10px; }


.submitted-btn {
  background-color: #5CB85C !important;
}

.submit-success {
  font-weight: 700;
  position: relative;
  top: 8px;
}

/* services */
#services {
  position: relative;
  color: #fff;
  background-color: $darkGrey;
  padding: 7em 0;

  .icon {
    font-size: 6.3em;
  }

  h3 {
    margin-top: 0.75em;
    font-size: 1.1em;
  }

  p {
    font-size: 0.9em;
    color: #9b9b9b;
    max-width: 200px;
    margin: 2.5em auto 0 auto;
    line-height: 2em;
  }
}


/* about */
#about {
  color: #fff;
  background-color: #163e6c;
  padding: 7em 0;

  h1 {
    font-size: 3em;
    text-align: center;
  }

  p {
    max-width: 400px;
    color: #ccc;
    padding: 0 2em;
    margin: 0 auto;
    line-height: 2em;

    &:nth-child(2) {
      margin-top: 1.8em;
    }
  }

}


/* banner */
.banner {
  position: relative;
  height: 400px;
  color: #fff;
  background: url("../img/banner.jpg") no-repeat center center;
  background-size: cover;
  background-attachment: fixed;

  h1 {
    position: relative;
    font-family: 'Pacifico', cursive;
    font-size: 6vw;
    margin: 0 0 1em 0;
  }

  h2 {
    position: relative;
    font-size: 1.2em;
    margin-top: 3em;
  }
}


.down-arrow {
  position: absolute;
  left: 50%;
  width: 60px;
  margin-left: -30px;
  bottom: 0;
  font-size: 5em;
  opacity: 0.0;
  text-decoration: none;
  color: #fff;
  z-index: 1;
  -webkit-animation: pulsate 2.5s ease-out;
  -webkit-animation-iteration-count: infinite;
  -moz-animation: pulsate 2.5s ease-out;
  -moz-animation-iteration-count: infinite;
  -o-animation: pulsate 2.5s ease-out;
  -o-animation-iteration-count: infinite;
  animation: pulsate 2.5s ease-out;
  animation-iteration-count: infinite;

  &:hover {
    color: #fff;
    text-decoration: none;
    /* -webkit-animation-play-state: paused; */
    /* animation-play-state: paused; */
    /* transform: scale(1); */
    /* -webkit-animation: pulsateStopStop 2.5s ease-out; */
    /* -moz-animation: pulsateStop 2.5s ease-out; */
    /* -o-animation: pulsateStop 2.5s ease-out; */
    /* animation: pulsateStop 2.5s ease-out; */
  }
}


@-webkit-keyframes pulsate {
  0% {-webkit-transform: scale(0.1, 0.1); opacity: 0.0;}
  50% {opacity: 1.0;}
  100% {-webkit-transform: scale(1.2, 1.2); opacity: 0.0;}
}

@-moz-keyframes pulsate {
  0% {-moz-transform: scale(0.1, 0.1); opacity: 0.0;}
  50% {opacity: 1.0;}
  100% {-moz-transform: scale(1.2, 1.2); opacity: 0.0;}
}

@-o-keyframes pulsate {
  0% {-o-transform: scale(0.1, 0.1); opacity: 0.0;}
  50% {opacity: 1.0;}
  100% {-o-transform: scale(1.2, 1.2); opacity: 0.0;}
}

@keyframes pulsate {
  0% { transform: scale(0.1, 0.1); opacity: 0.0;}
  50% { opacity: 1.0; }
  100% { transform: scale(1.2, 1.2); opacity: 0.0;}
}

/* @-webkit-keyframes pulsateStop { */
/*   0% {-webkit-transform: scale(0.1, 0.1); opacity: 0.0;} */
/*   50% {opacity: 1.0;} */
/*   100% {-webkit-transform: scale(1.2, 1.2); opacity: 0.0;} */
/* } */

/* @-moz-keyframes pulsateStop { */
/*   0% {-moz-transform: scale(0.1, 0.1); opacity: 0.0;} */
/*   50% {opacity: 1.0;} */
/*   100% {-moz-transform: scale(1.2, 1.2); opacity: 0.0;} */
/* } */

/* @-o-keyframes pulsateStop { */
/*   0% {-o-transform: scale(0.1, 0.1); opacity: 0.0;} */
/*   50% {opacity: 1.0;} */
/*   100% {-o-transform: scale(1.2, 1.2); opacity: 0.0;} */
/* } */

/* @keyframes pulsateStop { */
/*   0% { transform: scale(0.1, 0.1); opacity: 0.0;} */
/*   50% { opacity: 1.0; } */
/*   100% { transform: scale(1.2, 1.2); opacity: 0.0;} */
/* } */

/* Media queries */
@media (min-width: 970px) {
  #services p {
    font-size: 1em;
    max-width: 250px;
  }
}

@media (min-width: 1170px) {

  #services {
    p {
      font-size: 1.1em;
      max-width: 300px;
    }
    .icon {
      font-size: 8em;
    }

    h3 {
      font-size: 1.4em;
    }

  }
}

@media (max-width: 768px) {

  nav {
    display: none;
  }

  .banner {
    background-attachment: scroll;

    h1 {
      font-size: 3em;
    }
  }

  .logo img {
    display: block;
    margin: 0 auto;
  }

  .service {
    margin-top: 6em;
  }

  .service-1 {
    margin-top: 0;
  }

  #about {
    h1 {
      margin-top: 0;
    }

    p {
      text-align: center;
      margin: 2em auto;
    }
  }


  .contact-form {
    margin-top: 1em;
    width: 330px;

    input, textarea {
      width: 330px;
    }

    input {
      height: 55px;
    }
  }

  #contact .con-left img {
    display: none;
  }

  #contact {
    ul {
      margin-left: 0;

      li {
        font-size: 1.3em;
        margin-top: 1.3em;
        text-align: left;
      }
    }

    h2 {
      margin-top: 4.5em;
    }
  }

}
